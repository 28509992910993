// Custom Modals

.modal-content {
  overflow: auto;
  max-height: 95vh;
}

.modal {
  overflow-y: hidden;
}

.modal-body {
  padding: 0px 20px;
}

.modal-close-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
  font-size: 1.25rem;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
  z-index: 1065;
}

.modal-backdrop.show:nth-last-child(2) {
  z-index: 1060;
}

.document-upload-alerts {
  max-height: 25vh;
  overflow-y: auto;
}

.document-upload-alerts::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.document-upload-alerts::-webkit-scrollbar-track {
  background-color: #EDEDED;
}

.document-upload-alerts::-webkit-scrollbar-thumb {
  background-color: $gray-600;
  border: 1px solid #EDEDED;
  border-radius: 25px;
}

.document-upload-alerts::-webkit-scrollbar-thumb:hover {
  background-color: $gray-700;
}

.modal-footer {
  border: none;
}