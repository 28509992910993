// Color Modes

@include custom-color-mode(
  "custom1",
  // Grays
  $custom-gray-100: #F9F9F9,
  $custom-gray-200: #ECECEC,
  $custom-gray-300: #E2E2E2,
  $custom-gray-400: #D4D4D4,
  $custom-gray-500: #B5B5B5,
  $custom-gray-600: #757575,
  $custom-gray-700: #505050,
  $custom-gray-800: #3A3A3A,
  $custom-gray-900: #262626,
  // Color Variants
  $custom-primary:       #E5193B,
  $custom-success:       #38775D,
  $custom-info:          #3058C6,
  $custom-warning:       #F5C244,
  $custom-danger:        #F15937,
  // $custom-secondary:     $orange,
);



@include custom-color-mode(
  "custom2",
  // Grays
  $custom-gray-100: #F7FAFB,
  $custom-gray-200: #E6F1F3,
  $custom-gray-300: #D9E9EC,
  $custom-gray-400: #C5DEE3,
  $custom-gray-500: #A3C1C7,
  $custom-gray-600: #588892,
  $custom-gray-700: #3C5D64,
  $custom-gray-800: #26484E,
  $custom-gray-900: #182E33,
  // Color Variants
  $custom-primary:       #68C6EE,
  $custom-success:       #3F8357,
  $custom-info:          #3568F8,
  $custom-warning:       #F5C244,
  $custom-danger:        #CA444A,
  // $custom-secondary:     $orange,
);
