.list-group-item {
  padding: 1.25rem 1.5rem;
}

// .collapse-nav,
// .list-group-item {
//   width: 75%;
//   @media (max-width: 991px) {
//     width: 100%;
//   }
// }