/* Cards */
.card {
  // border: 0;
  // background-color: transparent;
  .card-header { border-bottom: 0px; }

  .card-body {
    padding: $card-padding-y $card-padding-x;
    + .card-body {
      padding-top: 0;
    }
  }

  &.card-outline-success { border: 1px solid theme-color("success"); }
	&.card-outline-primary { border: 1px solid theme-color("primary"); }
  &.card-outline-warning { border: 1px solid theme-color("warning"); }
  &.card-outline-danger { border: 1px solid theme-color("danger"); }
	&.card-rounded { @include border-radius(5px); }

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }

  &.card-circle-progress {
    color: $white;
    text-align: center;
  }

  &.reports-card {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    min-height: 15rem;

    .reports-card-icon {
      font-size: 1.75rem;
    }

    &:hover{
      background-color: $gray-100;
    }
  }

  &.sftp-card {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    min-height: 12rem;
    color: $gray-600;

    &:hover:not(.disabled) {
      background-color: $gray-100;
      color: $black;
    }
  }
}
