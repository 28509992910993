/* Link */
.thumb-link {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;

  /* Image */
  img {
    width: 100%;
    height: auto;
  }

  /* Icon - Defaults */
  .icon-video {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    line-height: 0.5;
    color: $white;
    z-index: 2;
    top: 45%;
    font-size: 4em;
  }

  /* Thumbnail & Director Content */
  .thumb_content {
    position: absolute;
    height: 20%;
    bottom: 0%;
    width: 100%;
    line-height: 1.2;
    z-index: 2;
    color: $white;
    justify-content: center;
    font-size: 14px;
  }

  /* Image Overlay */
  .image-overlay {
    position: absolute;
    width: 100%;
    height: 20%;
    bottom: 0%;
    background: $black;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
    opacity: 0.6;
  }

  /* Image Overlay Play button */
  .image-overlay-video-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0%;
    background: $black;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
    opacity: 0.3;
  }
}

/* Vimeo */
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
