// Custom Forms

// @import "forms/labels";
// @import "forms/form-text";
// @import "forms/form-control";
// @import "forms/form-select";
// @import "forms/form-check";
// @import "forms/form-range";
// @import "forms/floating-labels";
// @import "forms/input-group";
// @import "forms/validation";


.form-group {
  &.form-relative { position: relative; }

  &.read-only {
    margin-bottom: 2rem;
  }

  // Inline margin-bottom for sizes larger than small
  @include media-breakpoint-up(sm) {
    &.read-only.row {
      margin-bottom: 1rem;
    }
  }

  .icon-end {
    position: absolute;
    right: 0.75rem;
    top: calc(50%);
  }

  .form-label {
    font-size: 1rem;
    font-weight: $font-weight-semibold;
    color: #564d4a;
    line-height: 1.5;
    vertical-align: top;
    margin-bottom: 2px;
  }

  &.has-danger {
    .form-control,
    .form-select,
    .form-check-input[type=radio],
    .form-check-input[type=checkbox] {
      border-color: $danger;
    }

    .react-select-control {
      border-color: $danger !important;
    }
  }
}



// Radio
.form-check-input[type=radio] {
  border-color: $gray-500;

  &:hover:not(:disabled, :focus) {
    border-color: $gray-600;
  }

  &:active {
    box-shadow: none;
    background: none;

    &:not(:disabled) {
      border-color: $primary;
    }
  }

  &:focus {
    border-color: $primary;
    box-shadow: 0 0 .25rem $input-focus-width rgba($primary, .5);
  }

  &:checked {
    background: radial-gradient($primary 0%, $primary 50%, transparent 55%, transparent);

    &:hover,
    &:active {
      background: radial-gradient(shade-color($primary, $btn-hover-bg-shade-amount) 0%, shade-color($primary, $btn-hover-bg-shade-amount) 50%, transparent 55%, transparent);
    }

    &:disabled {
      background: radial-gradient($gray-600 0%, $gray-600 50%, transparent 55%, transparent);
    }
  }
}

.form-check {
  .form-check-wrapper {
    margin-left: $form-check-padding-start * -1;
    float: left;
  }

  .form-check-input[type=checkbox] {
    margin-left: 0;
    float: none;
  }
}


// Checkbox
.form-check-input[type=checkbox] {
  border-color: $gray-500;

  &:hover:not(:disabled, :focus) {
    border-color: $gray-600;
  }

  &:active {
    box-shadow: none;
    background: none;

    &:not(:disabled) {
      border-color: $primary;
    }
  }

  &:focus {
    border-color: $primary;
    box-shadow: 0 0 .25rem $input-focus-width rgba($primary, .5);
  }

  &:checked,
  &:indeterminate {
    background-color: $primary;

    &:hover,
    &:active {
      background-color: shade-color($primary, $btn-hover-bg-shade-amount);
    }

    &:disabled {
      background-color: $gray-600;
    }
  }
}



.form-control,
.form-select {
  border: $input-border-width solid $input-border-color;
  font-size: $input-font-size;
  padding: $input-padding-y $input-padding-x;
  height: 48px;
  border-radius: 3px;

  &:hover {
    border-color: $gray-500;
  }

  &:active {
    border-color: $primary;
  }

  &:focus {
    border-color: $primary;
    box-shadow: 0 0 .25rem $input-focus-width rgba($primary, .5);
  }

  &:disabled {
    background-color: $gray-100;
    border-color: $input-border-color;
  }
}



.react-select-control {
  border: $input-border-width solid $input-border-color !important;
  box-shadow: none !important;
  // font-size: $input-font-size;
  // padding: $input-padding-y $input-padding-x;
  height: 48px;
  // border-radius: 3px;

  &:hover {
    border-color: $gray-500 !important;
  }

  &:active {
    border-color: $primary !important;
  }

  &:focus {
    border-color: $primary !important;
    box-shadow: 0 0 .25rem $input-focus-width rgba($primary, .5) !important;
  }

  &:disabled {
    background-color: $gray-100 !important;
    border-color: $input-border-color !important;
  }
}

.react-select-multivalue {
  background-color: tint-color($primary, 80%) !important;
}

.react-select-multivalue-remove:hover {
  background-color: tint-color($danger, 80%) !important;
  color: $danger !important;
}



.input-group-text {
  z-index: 2;
  background-color: $white;
}



.react-switch {
  .react-switch-bg {
    border: 1px solid $white;
  }

  &.switch-checked {
    .react-switch-bg {
      background-color: $primary !important;
    }

    &:hover:not(.switch-disabled),
    &:active:not(.switch-disabled) {
      .react-switch-bg {
        background-color: shade-color($primary, $btn-hover-bg-shade-amount) !important;
      }
    }
  }

  &:not(.switch-checked) {
    .react-switch-bg {
      background-color: $gray-500 !important;
    }

    &:hover:not(.switch-disabled),
    &:active:not(.switch-disabled) {
      .react-switch-bg {
        background-color: $gray-600 !important;
      }
    }
  }

  &:focus-within {
    .react-switch-bg {
      border: 1px solid #ffffff;
      outline: 1px solid $primary;
      // outline-offset: -2px;
      box-shadow: 0 0 .25rem .1rem rgba($primary, .5);
    }
  }
}

/* Forms Legacy */
.form-control {
  border: 1px solid $border-color;
  font-weight: $font-weight-normal;
  font-size: $input-font-size;
  padding: $input-padding-y $input-padding-x;
  border-radius: 3px;

  &:disabled,
  &[readonly] {
    background-color: $gray-100 !important;
    border-color: $gray-100 !important;
    cursor: auto;
    opacity: 1;
  }
}

.form-group {
  label {
    font-weight: $font-weight-normal;
    font-size: 1rem;
    line-height: 1.5;
    vertical-align: top;
    margin-bottom: 2px;
  }

  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }

  &.form-relative { position: relative; }

  .icon-end {
    position: absolute;
    right: 1rem;
    height: 18px;
    top: calc((100% + 9px) / 2);
  }

  .code-input {
    display: inline-block;
    width: 45px;
    height: 65px;
    margin: 0px 10px;
    font-size: 1.8rem;
    text-align: center;

    @media (max-width: 576px) {
      width: 30px;
      height: 48px;
      margin: 0 4px;
      font-size: 1rem;
      padding: 16px 10px;
    }
  }
}

.hover-row {
  &:hover {
    background-color: $gray-100;
  }
}

.form-check-input {
  &:disabled ~ .form-check-label {
    color: $black;
  }
}

.sticky-dropmenu-menu-column {
  max-height: 35vh;
  overflow-y: auto;
}