// Custom Nav
.nav-tabs, .meter-nav {
  border: none;

  .nav-item {
    .nav-link {
      color: $black;
      border-top-left-radius: 0%;
      border-top-right-radius: 0%;
      padding-top: 5px;
      padding-bottom: 3px;
      border: none;
      border-bottom: 3px solid $gray-200;

      &.meter-link {
        color: $gray-700;
        display: inline-block;
        width: 100%;
        text-decoration: none;
        padding: 8px 0px 8px 18px;
      }
      &:not(.meter-link) {
        padding-top: 5px;
        padding-bottom: 3px;
      }

      &.active {
        font-weight: 500;
        border: none;
        border-bottom: 3px solid $primary;
      }
      &.disabled {
        color: $gray-500;
      }
      &:hover, &:active {
        background-color: $gray-200;
      }
    }

    &.nav-item-vertical {
      margin-bottom: 2px;
      text-align: center;
    }
  }
}
