$sticky-header-height: 55px;

.sticky-container {
  width: 600px;
  height: $sticky-header-height;
  position: fixed;
  right: 8px;
  bottom: 0;
  z-index: 1041;
  transition: height 0.25s ease-in;

  &.active {
    height: 800px;

    .sticky-body {
      height: calc(100% - #{$sticky-header-height});
      padding: 16px;
      overflow-y: auto;
    }
  }

  .sticky-card {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .sticky-header {
    width: 100%;
    height: $sticky-header-height;
    position: absolute;
    background-color: $gray-400;
    padding: 14px 20px;
    color: $white;
    right: 0;
    border-radius: 10px 10px 0 0;
  }
  
  .sticky-body {
    width: 100%;
    height: 0%;
    border-left: 1px solid $gray-400;
    border-right: 1px solid $gray-400;
    background-color: $white;
    padding: 0px;
    position: absolute; 
    right: 0;
    bottom: 0;
    transition: height 0.25s ease-in;
  }

  .sticky-icon {
    color: #6E757C;
  }

  @media (max-width: 1440px) {
    width: 500px;
  }
  
  @media (max-width: 576px) {
    width: 100%;
    right: 0;
  }
}
