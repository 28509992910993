.start-communication-btn {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #AEB5BC;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

#open-communication-templates-toggle,
#paste-templates-toggle {
  &::after {
    content: none;
  }
}

.communication-template-button {
  button {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #AEB5BC;
    border-color: transparent;
    background-clip: padding-box;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
}

.dropdown-communication-template {
  max-height: 262px;
  overflow-y: auto;
  max-width: 301px;

  .dropdown-title {
    display: inline-block;
    max-width: 100%;
    text-wrap: wrap;
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}

.manage-template {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}