// FONTS

// Roboto
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 200 300;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 200 300;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600 700;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 600 700;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 800 900;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 800 900;
  font-display: swap;
}
