.file-icon {
  background: linear-gradient(rgb(238, 238, 238), rgb(221, 221, 221));
  border-radius: 20px;
  height: 120px;
  width: 100px;
  z-index: 10;
}

.file-dropzone {
  border-style: dashed;
  border-width: 2px;
  border-radius: 20px;
  border-color: rgb(204, 204, 204);
}