.communication {
  &-notification {
    width: 10px;
    height: 10px;
    background-color: $danger;
    border-radius: $border-radius-pill;
  }

  /* Main content area */
  &-main {
    transition: margin-right 0.3s ease;
    /* Smooth transition for sidebar animation */
  }

  /* Sidebar */
  &-sidebar {
    position: absolute;
    top: 0;
    right: -375px;
    /* Initially hidden to the right */
    max-width: 375px;
    box-shadow: -5px 0 10px RGB($dark, 0.1);
    transition: right 0.3s ease;
  }

  @media (max-width: 768px) {
    &-sidebar {
      width: 275px;
      /* Adjusted width for extra small screens */
    }
  }

  /* Show sidebar when open */
  &-sidebar.open {
    right: 0;
  }

  /* Adjust main content area when sidebar is open */
  &-main.sidebar-open {
    margin-right: 375px;
  }
}

#reply-templates {
  border: 1px solid $gray-300;
  color: $gray-700;
}

.table-reply-body {
  p {
    margin: 0;
  }
}

@keyframes highlightFadeIn {
  0% {}

  50% {
    color: $warning;
    font-weight: 700;
  }

  100% {}
}

.highlighted {
  animation: highlightFadeIn 1.5s ease forwards;
}


/* CONTAINERS */ 
// https://developer.mozilla.org/en-US/docs/Web/CSS/@container


/* 
  Create the container / set the containment context 
  NOTE - can't style the element that the container query is set on
*/
.communications-wrapper {
  container-type: inline-size;
  container-name: communication-container;
  /* or using shorthand syntax like the following :
     container: anyvalidname / inline-size;
  */

}

/* Define the container query using @container */
/* The rule below says: find the closest ancestor with a
containment context 
- in this case "card-wrapper-container" -
and when the width is 768 pixels or less, 
change header to small  */

@container communication-container (max-width: 768px) {
  h4 {
    font-size: 1rem;
  }

  .sidebar-text {
    display: none; /* Hide the text on smaller screens */
  }

  // https://developer.mozilla.org/en-US/docs/Web/CSS/@container#nested_container_queries

  @container (max-width: 500px) {
    .col-lg-8,
    .col-md-10 {
      width: 100%;
      margin-left: 0%;
    }
  }

  @container (max-width: 300px) {    
    .communications-footer-btn {
      display: none;
    }
  }
}

@container communication-container (min-width: 769px) {
  .fas.fa-bars {
    display: none;
  }
}

.communication-block {
  p {
    margin: 0;
  }
}
