.message {
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.message-container {
  border: 1px solid $gray-400;
  border-radius: 6px;
}

#message-container {
  max-height: 460px;
  overflow: auto;
  padding: 16px;
}

// Message Count on Nav
.fas[data-count] {
  position: relative;

  &::after {
    position: absolute;
    content: attr(data-count);
    right: -1em;
    top: -1.5em;
    padding: .4em;
    border-radius: 10em;
    line-height: .9em;
    color: $white;
    background: rgba(179,63,51,.75);
    text-align: center;
    min-width: 2em;
    font: bold .7em sans-serif;
  }
}