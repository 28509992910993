.application-title {
  font-size: 20px;
}

.status-icon {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  font-size: 1rem;
  margin-left: 10px;
  &.status-icon-down {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.chevron-icon {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  font-size: 1.2rem;
  &.chevron-icon-down {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.upload-file {
  border: 1px dashed $gray-300;
  border-radius: 3px;
}