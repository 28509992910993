// Custom Type

b, strong {
  font-weight: 700;
}

.fs-7 {
  font-size: .875rem !important;
}

.fs-8 {
  font-size: .75rem !important;
}

.fs-9 {
  font-size: .625rem !important;
}
