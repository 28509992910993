// Custom Breadcrumb

/* inverse breadcrumb */
@each $color, $value in $theme-colors {
  .bg-inverse-#{$color} {
    @include breadcrumb-inverse-variant($value);
  }
}


.breadcrumb {
  color: $gray-600;

  .breadcrumb-item {
    &:not(.active):not(.disabled) {
      cursor: pointer;
      &:hover {
        color: $primary;
      }
      &:active {
        color: $primary;
        text-decoration: underline;
      }
      &:focus {
        span {
          color: $primary;
          border: 1px solid $primary;
          border-radius: 3px;
          box-shadow: 0px 0px 3px 0px $primary;
        }
      }
    }
    &.active {
      font-weight: 700;
    }
    &.disabled {
      color: $gray-400;
    }
    &::before {
      color: $gray-600;
      font-weight: 900;
    }
  }
}

// Links
.text-link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

nav[aria-label='breadcrumb'] {
  container-type: inline-size;
  container-name: breadcrumb-container;
}

@container breadcrumb-container (max-width: 568px) {
  .breadcrumb-item.active {
    max-width: 400px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .breadcrumb-item:not(.active) button {
    font-size: 0px;
  }
  .breadcrumb-item:not(.active) button::after {
    content: '...'; /* Display '...' using ::after pseudo-element */
    font-size: $font-size-base;
  }
}
