// Custom Dropdown

.no-arrow.dropdown-toggle::after {
  display: none;
}

.dropdown-divider {
  background-color: transparent;
}

// Collapsible Title
.chevron-icon {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  font-size: 1.2rem;

  &.chevron-icon-down {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

a.dropdown-item {
  text-decoration: none;
  color: $dropdown-link-color !important;

  &:not(.disabled) {
    span {
      color: $dropdown-link-color !important;
      width: 20px;
    }
  }

  &.disabled {
    span {
      color: $dropdown-link-disabled-color !important;
      width: 20px;
    }
  }
}

.dropdown-item:active {
  background-color: $gray-300 !important;

  h6 {
    color: $black;
  }
}
