// Custom Progress
.sm-progress {
  height: 0.75rem;
}

.sm-progress-text {
  font-size: 14px;
}

.xs-progress {
  height: 0.5rem;
}

.xs-progress-text {
  font-size: 10px;
}

.delay-progress-finish {
  animation: fadeout 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  60% {
    opacity: .9;
  }
  100% {
    opacity: 0;
  }
}