.app-wrapper {
  padding-top: $navbar-height;
  min-height: calc(100vh - #{$footer-height});
  // padding-bottom: $footer-height;

  .landing-page {
    .hero-img {
      width: 100%;
      height: 50%;
      // background-image: url("../assets/images/landing.jpg");
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      color: white;
      .hero-content {
        background: rgba(56, 49, 46, 0.8);
        padding: 30px;
      }
    }
    .bg-img {
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
    }
  }
  .prescreen-container {
    min-height: calc(100vh - 200px);
    background-color: $light;
  }
  @media (max-width: 768px) {
    padding-bottom: 0px;
  }
  .static-documents {
    li {
      font-size: 1rem;
    }
  }
}

.skip-link {
  position: absolute;
  width: 150px;
  height: 30px;

  &:focus {
    position: static;
  }
}