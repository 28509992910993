
input[type='checkbox'] {
  &.custom-checkbox {
  // display: none;
  display: inline-block;
  position: relative;
  top: -1px;
  width: 17px;
  height: 17px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: white left top no-repeat;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 4px;
  }
  + #check {
    display: none;
    // display: inline-block;
    // position: relative;
    // top: -1px;
    // width: 17px;
    // height: 17px;
    // margin: -1px 0px 0 0;
    // vertical-align: middle;
    // background: white left top no-repeat;
    // border: 1px solid #ccc;
    // border-radius: 3px;
    // cursor: pointer;
    // margin-right: 4px;
  }
  &:disabled + #check {
    border: $gray-200 1px solid;
    background: $gray-100;
  }
  &:checked {
    + #check {
      &:before {
        color: $white;
        content: '✔';
        display:inline-block;
        vertical-align: top;
        line-height: 1em;
        width: 5px;
        height: 5px;
        margin-right: 0.1em;
        text-align: center;
      }
      background: $primary -19px top no-repeat;
      border: $gray-200 1px solid;
      border-radius: 3px;
    }
    &:disabled + #check {
      background: $gray-800 -19px top no-repeat;
    }
  }
}
