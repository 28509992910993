// Custom Spinners

.spinner-example {
  // width: 50px; /* Adjust the size as needed */
  // height: 50px; /* Adjust the size as needed */
  border: 11.5px solid #b6b8dc;
  border-top: 11.5px solid #016f66;
  border-radius: 50%;
  animation: spinner-d3wgkg 1s infinite linear;
}

@keyframes spinner-d3wgkg {
  to {
    transform: rotate(1turn);
  }
}