// MISC
body {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: initial;
}

.cursor {
  cursor: default;
}

.pointer {
  cursor: pointer;
}

.svg-stroke {
  stroke: $gray-700;
  &.selected {
    stroke: $primary;
  }
}

.bg-light-hover:hover {
  background-color: $gray-100;
}

// Background color utilities
.bg-primary {
  @include text-contrast($primary);
  &.clickable:hover {
    background-color: shade-color($primary, 15%) !important;
    @include text-contrast(shade-color($primary, 15%));
  }
}

.bg-secondary {
  @include text-contrast($secondary);
  &.clickable:hover {
    background-color: shade-color($secondary, 15%) !important;
    @include text-contrast(shade-color($secondary, 15%));
  }
}

.bg-success {
  @include text-contrast($success);
  &.clickable:hover {
    background-color: shade-color($success, 15%) !important;
    @include text-contrast(shade-color($success, 15%));
  }
}

.bg-info {
  @include text-contrast($info);
  &.clickable:hover {
    background-color: shade-color($info, 15%) !important;
    @include text-contrast(shade-color($info, 15%));
  }
}

.bg-warning {
  @include text-contrast($warning);
  &.clickable:hover {
    background-color: shade-color($warning, 15%) !important;
    @include text-contrast(shade-color($warning, 15%));
  }
}

.bg-danger {
  @include text-contrast($danger);
  &.clickable:hover {
    background-color: shade-color($danger, 15%) !important;
    @include text-contrast(shade-color($danger, 15%));
  }
}



// Subtle variant backgrounds
.bg-primary-subtle {
  background-color: tint-color($primary, 80%);
  @include text-contrast(tint-color($primary, 80%));
  &.clickable:hover {
    background-color: tint-color($primary, 70%) !important;
    @include text-contrast(tint-color($primary, 70%));
  }
}

.bg-secondary-subtle {
  background-color: tint-color($secondary, 80%);
  @include text-contrast(tint-color($secondary, 80%));
  &.clickable:hover {
    background-color: tint-color($secondary, 70%) !important;
    @include text-contrast(tint-color($secondary, 70%));
  }
}

.bg-success-subtle {
  background-color: tint-color($success, 80%);
  @include text-contrast(tint-color($success, 80%));
  &.clickable:hover {
    background-color: tint-color($success, 70%) !important;
    @include text-contrast(tint-color($success, 70%));
  }
}

.bg-info-subtle {
  background-color: tint-color($info, 80%);
  @include text-contrast(tint-color($info, 80%));
  &.clickable:hover {
    background-color: tint-color($info, 70%) !important;
    @include text-contrast(tint-color($info, 70%));
  }
}

.bg-warning-subtle {
  background-color: tint-color($warning, 80%);
  @include text-contrast(tint-color($warning, 80%));
  &.clickable:hover {
    background-color: tint-color($warning, 70%) !important;
    @include text-contrast(tint-color($warning, 70%));
  }
}
.bg-danger-subtle {
  background-color: tint-color($danger, 80%);
  @include text-contrast(tint-color($danger, 80%));
  &.clickable:hover {
    background-color: tint-color($danger, 70%) !important;
    @include text-contrast(tint-color($danger, 70%));
  }
}

// GRAY
.bg-gray-100 {
  background-color: $gray-100;
  @include text-contrast($gray-100);
  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-200 {
  background-color: $gray-200;
  @include text-contrast($gray-200);
  &.clickable:hover {
    background-color: $gray-300 !important;
    @include text-contrast($gray-300);
  }
}

.bg-gray-300 {
  background-color: $gray-300;
  @include text-contrast($gray-300);
  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-400 {
  background-color: $gray-400;
  @include text-contrast($gray-400);
  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-500 {
  background-color: $gray-500;
  @include text-contrast($gray-500);
  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-600 {
  background-color: $gray-600;
  @include text-contrast($gray-600);
  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-700 {
  background-color: $gray-700;
  @include text-contrast($gray-700);
  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-800 {
  background-color: $gray-800;
  @include text-contrast($gray-800);
  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

.bg-gray-900 {
  background-color: $gray-900;
  @include text-contrast($gray-900);
  &.clickable:hover {
    background-color: $gray-200 !important;
    @include text-contrast($gray-200);
  }
}

/* Scroll To Top Button */
.btn-scroll-to-top {
  position: fixed;
  visibility: hidden;
  right: 10px;
  width: 44px;
  height: 44px;
  background-color: $primary;
  opacity: 0;
  border-radius: 50%;
  border: none;
  z-index: 1031;
  transition: all ease .1s;

  i {
    color: $white;
    font-size: 1.2em;
  }

  &.show {
    visibility: visible;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }
}

/* Miscellanoeous */
body {
  background: $sidebar-light-bg;
}

pre {
  background: gray(300);
  padding: 15px;
  font-size: 14px;
}

code {
  background: gray(200);
  padding: .2rem .4rem;
}

a.no-link:hover,
a.no-link:focus {
  text-decoration: none !important;
}

.pointer {
  cursor: pointer;
}

.bg-hover {
  &:hover {
    background: $secondary;
  }
}
